import React from "react"
import { Router } from "@reach/router"

// import app components
import { AccountSettings, PrivateRoute } from "auth/components"

export default () => {
  return (
    <Router>
      <PrivateRoute path="/account/settings" component={AccountSettings} />
    </Router>
  )
}
